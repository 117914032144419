import Vue from "vue";
import moment from "moment";
import generalService from "../../axios/general";
import router from "../../router";
import Vuetify from "../../plugins/vuetify";
import store from "../";

import { checkForceReset } from "../../helpers/updater.js";

const getDefaultState = () => {
  return {
    client: null,
    clientUrl: null,
    layout: null,
    language: navigator.language || navigator.userLanguage,
    logo: null,
    lastRequest: null,
    sessionDuration: null,
    colors: {
      primary: null,
      navbar: null,
    },
    footer: {},

    navbarDefaultOpen: false,

    tabs: [],

    dateFormat: "DD-MM-YYYY",
    dateTimeFormat: "DD-MM-YYYY HH:mm",

    languages: [],
    currentLanguage: "",
    calendarDefault: "",

    sso: [],

    customerLinks: [],
    translations: [],

    errors: [],
    chatAvailable: false,
    newChangelog: false,
    paperstatsAvailable: false,
    helpAvailable: false,
    isSimpleSearch: false,
    defaultTabelRows: "",
    searchMaat: false,

    filterData: {
      months: [],
      years: [],
    },

    reset: {
      versions: [],
      lastDate: null,
    },
  };
};

const state = getDefaultState();

const getters = {
  getClient: (state) => state.client,
  getLanguage: (state) => state.language,
  getLayout: (state) => state.layout,
  getLogo: (state) => state.logo,
  getClientUrl: (state) => state.clientUrl,
  getFooter: (state) => state.footer,

  getColors: (state) => state.colors,
  getDateFormat: (state) => state.dateFormat,
  getDateTimeFormat: (state) => state.dateTimeFormat,

  getYears: (state) => state.filterData.years,
  getMonths: (state) => state.filterData.months,

  getNavbarDefaultOpen: (state) => state.navbarDefaultOpen,
  getTabs: (state) => state.tabs,

  getLanguages: (state) => state.languages,
  getCurrentLanguage: (state) => state.currentLanguage,

  getCustomerLinks: (state) => state.customerLinks,
  getCalendarDefault: (state) => state.calendarDefault,

  getSSO: (state) => state.sso,

  getTranslations: (state) => state.translations,
  getChatAvailable: (state) => state.chatAvailable,
  getNewChangelog: (state) => state.newChangelog,
  getPaperstatsAvailable: (state) => state.paperstatsAvailable,
  getHelpAvailable: (state) => state.helpAvailable,
  isSimpleSearch: (state) => state.isSimpleSearch,
  getDefaultTabelRows: (state) => state.defaultTabelRows,
  getSearchMaat: (state) => state.searchMaat,

  getErrors: (state) => state.errors,
  getLastRequestTime: (state) => state.lastRequest,
  getSessionDuration: (state) => state.sessionDuration,
  isSessionActive: (state) => {
    if (state.lastRequest && state.sessionDuration) {
      return (
        moment().diff(state.lastRequest, "seconds") < state.sessionDuration
      );
    }
    return false;
  },
  getReset: (state) => state.reset,
};

const actions = {
  async fetchGeneral({ commit, dispatch }) {
    await generalService.getGeneralData().then((response) => {
      if ("name" in response) commit("setClient", response.name);
      if ("layout" in response) {
        commit("setLayout", response.layout);

        const baseUrl =
          process.env.NODE_ENV == "development"
            ? process.env.VUE_APP_BASEURL
            : window.location.origin;

        let head = document.getElementsByTagName("head")[0];
        let link = document.createElement("link");
        link.id = "clientCss";
        link.rel = "stylesheet";
        link.type = "text/css";
        link.href = baseUrl + "/css/clientv/" + response.layout + ".css";
        link.media = "all";
        head.appendChild(link);
      }
      if ("language" in response) commit("setLanguage", response.language);
      if ("image_url" in response) commit("setImage", response.image_url);
      if ("date_format" in response)
        commit("setDateFormat", response.date_format);

      if ("date_time_format" in response)
        commit("setDateTimeFormat", response.date_time_format);

      if ("primary_color" in response) {
        commit("setPrimaryColor", response.primary_color);
        Vuetify.framework.theme.themes.light.primary = response.primary_color;
      }

      if ("footerInfo" in response) commit("setFooter", response.footerInfo);

      if ("navbar_color" in response)
        commit("setNavbarColor", response.navbar_color);

      if ("navbarDefaultOpen" in response)
        commit("setNavbarDefaultOpen", response.navbarDefaultOpen);

      if ("languages" in response) commit("setLanguages", response.languages);
      if (!("languages" in response))
        commit("setLanguages", '');
      if ("language" in response)
        commit("setCurrentLanguage", response.language);

      if ("customer_links" in response)
        commit("setCustomerLinks", response.customer_links);
      if (!("customer_links" in response))
        commit("setCustomerLinks", '');

      if ("client_url" in response) commit("setClientUrl", response.client_url);

      if ("menutabs" in response) commit("setMenuTabs", response.menutabs);

      if ("chat_available" in response)
        commit("setChatAvailable", response.chat_available);

      if ("new_changelog" in response)
        commit("setNewChangelog", response.new_changelog);

      if ("calendar_default" in response)
        commit("setCalendarDefault", response.calendar_default);

      if ("paper_stats_available" in response)
        commit("setPaperstatsAvailable", response.paper_stats_available);
      if ("help_available" in response)
        commit("setHelpAvailable", response.help_available);
      if ("default_rows" in response)
        commit("setDefaultTabelRows", response.default_rows);

      if ("translations" in response) {
        Vue.prototype.$translations = response.translations;
        commit("setTranslations", response.translations);
      }

      if ("session_expiration_time" in response)
        commit("setSessionDuration", response.session_expiration_time);

      if ("sso" in response) commit("setSSO", response.sso);

      if ("simple_search" in response)
        commit("setIsSimpleSearch", response.simple_search);

      if ("knownuser" in response && response.knownuser == 0)
        store.commit("setSID", null);

      if ("cookieClearTime" in response)
        checkForceReset(response.cookieClearTime);

      if ("search_maat" in response)
        commit("setSearchMaat", response.search_maat);

      const currentSID = store.getters.getSID;
      if ("sid" in response && currentSID != response.sid) {
        commit("setSID", response.sid);
        dispatch("fetchUserData");
      }

      const filterData = {
        months: [],
        years: [],
      };

      if ("months" in response) {
        filterData.months = response.months;
      }

      if ("startyear" in response) {
        const firstYear = response.startyear;
        const currentYear = moment().add(1, "year").year();
        filterData.years = Array.from(
          { length: currentYear - firstYear + 2 },
          (v, i) => i + firstYear
        ).reverse();
      }

      commit("setFilterData", filterData);
    });
  },
  setLang({ commit }, langCode) {
    generalService.setLanguage(langCode).then(() => {
      commit("setCurrentLanguage", langCode);
      router.go();
    });
  },
  resetGeneralState({ commit }) {
    commit("resetGeneralState");
  },
};

const mutations = {
  setClient: (state, client) => (state.client = client),
  setLanguage: (state, language) => {
    state.language = language;
    Vuetify.framework.lang.current = language;
    document.documentElement.setAttribute("lang", language);
  },
  setLayout: (state, layout) => (state.layout = layout),
  setImage: (state, logo) => (state.logo = logo),
  setDateFormat: (state, dateFormat) => (state.dateFormat = dateFormat),
  setDateTimeFormat: (state, dateTimeFormat) =>
    (state.dateTimeFormat = dateTimeFormat),
  setNavbarDefaultOpen: (state, navbarDefaultOpen) =>
    (state.navbarDefaultOpen = navbarDefaultOpen == true),
  setPrimaryColor: (state, primary_color) =>
    (state.colors.primary = primary_color),
  setNavbarColor: (state, navbar_color) => (state.colors.navbar = navbar_color),
  setFilterData: (state, filterData) => (state.filterData = filterData),
  setLanguages: (state, languages) => (state.languages = languages),
  setCurrentLanguage: (state, currentLanguage) =>
    (state.currentLanguage = currentLanguage),
  setCustomerLinks: (state, links) => (state.customerLinks = links),
  setTranslations: (state, translations) => (state.translations = translations),
  setSessionDuration: (state, duration) => (state.sessionDuration = duration),
  setLastRequest: (state) => (state.lastRequest = moment()),
  setFooter: (state, footer) => (state.footer = footer),
  setSSO: (state, sso) => (state.sso = sso),
  setClientUrl: (state, clientUrl) => (state.clientUrl = clientUrl),
  setMenuTabs: (state, tabs) => (state.tabs = tabs),
  setChatAvailable: (state, chatAvailable) =>
    (state.chatAvailable = chatAvailable),
  setNewChangelog: (state, newChangelog) => (state.newChangelog = newChangelog),
  setPaperstatsAvailable: (state, paperstatsAvailable) =>
    (state.paperstatsAvailable = paperstatsAvailable),
  setDefaultTabelRows: (state, defaultTabelRows) => (state.defaultTabelRows = defaultTabelRows),
  setHelpAvailable: (state, helpAvailable) =>
    (state.helpAvailable = helpAvailable),
  setIsSimpleSearch: (state, isSimpleSearch) =>
    (state.isSimpleSearch = isSimpleSearch),
  setResetDate: (state, value) => (state.reset.lastDate = value),
  setResetVersions: (state, value) => (state.reset.versions = value),
  setCalendarDefault: (state, value) => (state.calendarDefault = value),
  setSearchMaat: (state, searchMaat) =>
    (state.searchMaat = searchMaat),
  addError: (state, error) => state.errors.push(error),
  resetGeneralState(state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState());
  },
};

export default { state, getters, actions, mutations };

import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import icon from "./helpers/icon.js";
import color from "./helpers/color.js";
import styling from "./helpers/styling.js";
// plugins
import vuetify from "./plugins/vuetify";
import ICS from "vue-ics";

//components
import "./components/parts/index.js";
import { checkVersion } from "./helpers/updater.js";

Vue.config.productionTip = false;
Vue.use(store);
Vue.use(ICS, {
	prodId: "-//Docwolves//Docwolves Calendar//EN",
});

Vue.prototype.$translations = store.getters.getTranslations || {};
Vue.prototype.$global = Vue.observable({
	section: null,
	moduleSection: null,
});

Vue.prototype.$helpers = {
	color,
	icon,
	styling,
};

checkVersion();

new Vue({
	vuetify,
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
